@media (max-width: 480px) {
  .server-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .title-server {
    display: flex;
    align-items: center;
    color: white;
    font-size: 28px;
    justify-content: center;
    .btn-sv {
      width: 30px;
      padding-right: 10px;
    }
    span {
      font-family: "SVN-Gilroy-Regular", Helvetica;
      font-weight: 400;
      color: #ffffff;
      font-size: 1.5rem;
    }
  }

  .server-list {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    img {
      padding-bottom: 5px;
      width: 100%;
    }
  }

  .col1,
  .col2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    img {
      padding-bottom: 5px;
      width: 100%;
    }
  }

  .link2 {
    position: relative;
    padding: 0 30px;
    padding-top: 15px;
    background: #1d1d1f;
    border-radius: 50px;
    width: 100%;
    height: 50px;
    overflow: hidden;
    margin: 5px 0;
  }
  .link2::before {
    content: "";
    position: absolute;
    left: 0%;
    width: 500px;
    height: 30px;
    background-image: conic-gradient(
      transparent,
      transparent,
      transparent,
      #00ccff
    );
    animation: animate 4s linear infinite;
  }

  .link2::after {
    content: "";
    position: absolute;
    left: -180%;
    top: 120%;
    width: 1000px;
    height: 20px;
    background-image: conic-gradient(
      transparent,
      transparent,
      transparent,
      white
    );
    animation: animate 4s linear infinite;
  }

  .link2 .main-content {
    position: absolute;
    inset: 5px;
    background: linear-gradient(180deg, #dc0000, #dc0000);
    background-image: url("../asset/img/button.png");
    background-size: 120%;
    background-repeat: none;
    background-position: 50% 10%;
    border-radius: 50px;
    z-index: 2;
    font-family: "SVN-Gilroy-Regular", Helvetica;
    font-weight: 900;
    color: #ffffff;
    font-size: 1rem;
    text-align: left;
    align-self: center;
    padding: 12px 20px 12px 20px;
    border-width: 2px 0 0 0;
    border-style: ridge;
    border-color: white;
    box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.2);
  }

  .truycap {
    position: absolute;
    right: 15px;
    padding: 3px;
    color: white;
    border-radius: 50px;
    font-family: "SVN-Gilroy-Regular", Helvetica;
    font-size: 0.6em;
    box-shadow: 0px 2.93px 2.47px #00000042;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    z-index: 3;
  }
  /* 05207b */

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .server-select {
    flex: 1 1;
  }
  .title-server {
    align-items: flex-end;
    color: #fff;
    display: flex;
    font-size: 28px;
    justify-content: center;
    .btn-sv {
      padding-right: 10px;
      width: 50px;
    }
    span {
      color: #fff;
      font-family: SVN-Gilroy-Regular, Helvetica;
      font-size: 2rem;
      font-weight: 400;
    }
  }
  .link2:before {
    background-image: conic-gradient(#0000, #0000, #0000, #0cf);
    height: 30px;
    left: 0;
    width: 500px;
  }

  .link2:after,
  .link2:before {
    animation: animate 4s linear infinite;
    content: "";
    position: absolute;
  }
  .link2:after {
    background-image: conic-gradient(#0000, #0000, #0000, #fff);
    height: 20px;
    left: -180%;
    top: 120%;
    width: 1000px;
  }

  .link2 .main-content {
    align-self: center;
    border: ridge #fff;
    border-radius: 50px;
    border-width: 2px 0 0;
    box-shadow: inset 0 -4px 0 #0003;
    color: #fff;
    font-family: SVN-Gilroy-Regular, Helvetica;
    font-size: 0.rem;
    font-weight: 900;
    inset: 5px;
    position: absolute;
    text-align: left;
    z-index: 2;
  }
  .link2 .main-content {
    background: linear-gradient(180deg, #dc0000, #dc0000);
    background-image: url("../asset/img/button.png");
    background-size: 120%;
    background-repeat: none;
    background-position: 50% 10%;
  }
  .truycap {
    background: linear-gradient(90deg, #ed1a3b 0, #f58345);
    border-radius: 50px;
    box-shadow: 0 2.93px 2.47px #00000042;
    color: #fff;
    font-family: SVN-Gilroy-Regular, Helvetica;
    font-size: 0.9em;
    padding: 3px 10px 5px;
    position: absolute;
    right: 15px;
    z-index: 3;
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .link2 {
    border-radius: 50px;
    margin: 10px;
    overflow: hidden;
    position: relative;
  }
  .server-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    width: 100%;
    img {
      padding-bottom: 5px;
    }
    .col1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .link2 {
        height: 50px;
        transition: color 0.3s ease, transform 0.3s ease;
        width: 300px;
      }
      .link2:hover {
        transform: scale(1.1);
      }
      .link2 .main-content {
        display: flex;
        font-size: 1em;
        justify-content: left;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-top: 10px;
      }
      .link2:before {
        height: 50px;
        left: -150%;
        top: -150%;
        width: 2000px;
      }
      .link2:after {
        height: 50px;
        left: -150%;
        top: 150%;
        width: 2000px;
      }
      .truycap {
        font-size: 1em;
        right: 20px;
        top: 12px;
      }
    }
  }
}

@media (min-width: 1224px) {
  .server-select {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    width: 95%;
    padding-bottom: 50px;
  }
  .title-server {
    align-items: flex-end;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    .btn-sv {
      padding-right: 10px;
      width: 50px;
    }
    span {
      color: #fff;
      font-family: SVN-Gilroy-Regular, Helvetica;
      font-size: 2rem;
      font-weight: 400;
    }
  }
  .link2:before {
    background-image: conic-gradient(#0000, #0000, #0000, #0cf);
    height: 30px;
    left: 0;
    width: 500px;
  }

  .link2:after,
  .link2:before {
    animation: animate 4s linear infinite;
    content: "";
    position: absolute;
  }

  .link2:after {
    background-image: conic-gradient(#0000, #0000, #0000, #fff);
    height: 20px;
    left: -180%;
    top: 120%;
    width: 1000px;
  }

  .link2 .main-content {
    align-self: center;
    border: ridge #fff;
    border-radius: 50px;
    border-width: 2px 0 0;
    box-shadow: inset 0 -4px 0 #0003;
    color: #fff;
    font-family: SVN-Gilroy-Regular, Helvetica;
    font-size: 0.8rem;
    font-weight: 900;
    inset: 5px;
    position: absolute;
    text-align: left;
    z-index: 2;
  }
  .link2 .main-content {
    background: linear-gradient(180deg, #dc0000, #dc0000);
    background-image: url("../asset/img/button.png");
    background-size: 120%;
    background-repeat: none;
    background-position: 50% 10%;
  }
  .truycap {
    background: linear-gradient(180deg, #1a64ed 0, #4557f5);
    border-radius: 50px;
    box-shadow: 0 2.93px 2.47px #00000042;
    color: #fff;
    font-family: SVN-Gilroy-Regular, Helvetica;
    font-size: 0.9em;
    padding: 3px 10px 5px;
    position: absolute;
    right: 15px;
    z-index: 3;
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .link2 {
    border-radius: 50px;
    margin: 10px;
    overflow: hidden;
    position: relative;
  }
  .server-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    width: 100%;
    img {
      padding-bottom: 5px;
    }
    .col1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      .link2 {
        height: 50px;
        transition: color 0.3s ease, transform 0.3s ease;
        width: 30%;
      }
      .link2:hover {
        transform: scale(1.1);
      }
      .link2 .main-content {
        display: flex;
        font-size: 1.3em;
        justify-content: left;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-top: 10px;
      }
      .link2:before {
        height: 50px;
        left: -150%;
        top: -150%;
        width: 2000px;
      }
      .link2:after {
        height: 50px;
        left: -150%;
        top: 150%;
        width: 2000px;
      }
      .truycap {
        font-size: 1em;
        right: 20px;
        top: 12px;
      }
    }
  }
}
