@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.App {
  background-image: url(./components/asset/img/bg-1.webp);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  z-index: -9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.group-sv-boss {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1d1d1f;
}
.background-header {
  width: 100%;
  background: #1d1d1f;
}

@media (min-width: 1224px) {
  .group-sv-boss {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
  }
  .sv-boss-wrapper {
    display: flex;
    width: 1200px;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 481px) and (max-width: 1223px) {
  .group-sv-boss {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .sv-boss-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
